// Utils
import { ValueInterface, buildValueInterface, buildValueInterfaceFromBigNumberValues } from 'Utils/valueInterface';
import { MILLISECONDS_PER_DAY } from 'Constants/timeAndDates';

// Types
import { CommitmentLength } from 'Context/Commit/Commit';

// Constants
import { DEFAULT_APY, DRIPS_PER_DAY, USDC_DECIMALS, ZERO, APY_DECIMALS } from 'Constants';
import { CommitDays } from 'Graphql/schema';

export const getPointsEstimate = (depositAmount: bigint, monthsCommitment: number): ValueInterface => {
  const days = monthsCommitment === 3 ? 90 : monthsCommitment === 6 ? 180 : 30;
  const dripsPerDay = DRIPS_PER_DAY(days);
  const scale = BigInt(10 ** (USDC_DECIMALS + 1)); // divide by 10 to get original dripsPerDay float

  const calc = (depositAmount * BigInt(days) * BigInt(dripsPerDay)) / scale;

  return buildValueInterface(calc.toString(), 100);
};

export const getCommitmentEnds = (startTimestamp: number, days: number): number =>
  startTimestamp + days * MILLISECONDS_PER_DAY;

export const getYieldEstimate = (depositAmount: bigint, apy: bigint): ValueInterface => {
  const apyEstimate = apy === ZERO ? DEFAULT_APY : apy;

  const annualYieldScaled = depositAmount * apyEstimate;
  const annualYield = buildValueInterfaceFromBigNumberValues(annualYieldScaled, USDC_DECIMALS + APY_DECIMALS);

  return annualYield;
};

export const convertToCommitDays = (commitLength: CommitmentLength): CommitDays => {
  if (commitLength === '3') return CommitDays.Ninety;
  if (commitLength === '6') return CommitDays.OneHundredEighty;

  throw new Error('Invalid commitment length');
};

export const commitmentLengthMap = {
  90: '3 months',
  180: '6 months',
};

export const commitmentLengthMapShort = {
  '3 months': '3',
  '6 months': '6',
};
