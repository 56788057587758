import { useState } from 'react';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';

// Components
import MapleButton from 'Components/ds/MapleButton';
import MapleIcon from 'Components/ds/MapleIcon';
import MapleTypography from 'Components/ds/MapleTypography';
import MapleMenu from 'Components/ds/MapleMenu';
import { ValueInterface } from 'Utils/valueInterface';
import { PoolAssets } from 'Context/Portfolio/Portfolio';

export interface AssetProps extends MenuItemProps {
  id: string;
  decimals: number;
  symbol: string;
  balance: ValueInterface;
  targetYield?: number;
}

interface MapleAssetSelectProps<T> {
  assets: T[];
  selectedAsset: T;
  setSelectedAsset: (id: T) => void;
  assetHelperText?: string;
}

const MapleAssetSelect = ({
  assets,
  selectedAsset,
  setSelectedAsset,
  assetHelperText,
}: MapleAssetSelectProps<AssetProps>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    anchorEl?.blur();
    setAnchorEl(null);
  };

  const handleClickItem = (asset: AssetProps) => {
    setSelectedAsset(asset);
    handleClose();
  };

  return (
    <>
      <MapleButton
        color='secondary'
        size='medium'
        onClick={handleOpen}
        startIcon={<MapleIcon size={24} icon={selectedAsset.symbol.toLowerCase() as 'usdc'} />}
        endIcon={<MapleIcon size={24} icon={open ? 'arrowUpSLine' : 'arrowDownSLine'} />}
        sx={{
          borderRadius: theme => theme.shape.sm,
          px: 2.5,
          py: 1,
          '&:focus': { backgroundColor: theme => theme.palette.background.white },
        }}
      >
        {selectedAsset.symbol}
      </MapleButton>
      {assets.length > 1 && (
        <MapleMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {assets.map(asset => (
            <MenuItem key={asset.id} disabled={asset.disabled} onClick={() => handleClickItem(asset)}>
              <Stack
                direction='row'
                spacing={4}
                justifyContent='space-between'
                alignItems='center'
                sx={{ width: '100%' }}
              >
                <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
                  <MapleIcon icon={asset.symbol.toLowerCase() as PoolAssets} size={40} />
                  <Stack spacing={0}>
                    <MapleTypography variant='paragraph' size='medium' component='span' color='text.strong'>
                      {asset.symbol}
                    </MapleTypography>
                    {asset.targetYield && (
                      <MapleTypography
                        variant='paragraph'
                        size='small'
                        component='span'
                        color='text.soft'
                      >{`${asset.targetYield}% APY`}</MapleTypography>
                    )}
                  </Stack>
                </Stack>
                <Stack spacing={0} alignItems='end'>
                  <MapleTypography
                    variant='paragraph'
                    size='medium'
                    component='span'
                    color='text.strong'
                  >{`$${asset.balance.formatted}`}</MapleTypography>
                  <MapleTypography variant='paragraph' size='small' component='span' color='text.soft'>
                    {assetHelperText ? assetHelperText : 'Wallet'} Balance
                  </MapleTypography>
                </Stack>
              </Stack>
            </MenuItem>
          ))}
        </MapleMenu>
      )}
    </>
  );
};

export default MapleAssetSelect;
